@import './styles/styles.scss';

$black: #000000;
$blue1: #3670aa;
$blue2: #0e549b;
$blue3: #162e46;
$blue4: #3d678e;

html {
  background-color: #1F354B;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  background: 
    radial-gradient(circle at bottom, $black, transparent 80%),    
    linear-gradient(to top, dodgerblue 0%, transparent 70%),    
    linear-gradient(to bottom, transparent, rgba($black, 0.4) 70%),
    $blue2;  
  animation: bg 20s ease-in-out infinite;
}

h1 {
  font-size: 1.4rem;
}

h2 {
  font-size: 1.4rem;
}

.internal-link {
  font-weight: bold;
  width: fit-content;
  text-decoration: underline;
}

@keyframes bg {
  0%, 100% { background-color: $blue2; }
  25% { background-color: $blue3; }
  50% { background-color: $blue4; }
  75% { background-color: $blue1; }
}