$default-page-width: 75%;
$default-max-width: 1200px;

$text-color: white;
$text-color-dark: black;
$dark-blue: #1C2A35;
$modal-mask-color: rgba(0, 0, 0, 0.45);

$tablet-break-point: 815px;
$mobile-break-point: 550px;
