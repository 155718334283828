@import '../styles/styles.scss';

.page-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: $default-page-width;
    max-width: $default-max-width;
}

@media screen and (max-width: $tablet-break-point) {
    .page-wrapper {
        width: calc(100% - 32px);
        padding: 0 16px;
    }
}