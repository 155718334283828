.test-page {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .game-wrapper {
        width: 80%;
        background-color: red;

        padding: 16px;

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .sidebar {
        width: 20%;
    }
}