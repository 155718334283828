@import '../../styles/styles.scss';

@keyframes gentle-background-sway-horizontal {
    0% {
        background-position: 50% 50%;
    }
    22% {
        background-position: 40% 50%;
    }
    27% {
        background-position: 40% 50%;
    }
    72% {
        background-position: 60% 50%;
    }
    77% {
        background-position: 60% 50%;
    }
    100% {
        background-position: 50% 50%;
    }
}

@keyframes gentle-background-sway-vertical {
    0% {
        background-position: 50% 50%;
    }
    22% {
        background-position: 50% 60%;
    }
    27% {
        background-position: 50% 60%;
    }
    72% {
        background-position: 50% 40%;
    }
    77% {
        background-position: 50% 40%;
    }
    100% {
        background-position: 50% 50%;
    }
}


.projects-page {
    width: 100%;

    .projects-header {
        .title {
            font-size: 2em;
            text-align: center;
            margin-top: 0;
            margin-bottom: 2px;
            color: $text-color
        }

        .filter-wrapper{
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            padding-left: 8px;
            padding-right: 8px;

            .filter-label {
                color: $text-color;
            }

            .tag-select {
                flex: 1;
                max-width: 480px;
                max-height: 32px;
            }

            .filter-switch {
                background-color: #1677ff;;
            }
        }
    }

    .project-wrapper {
        display: grid;
        padding: 16px;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 300px;
        grid-auto-flow: dense;
        gap: 16px;
        
        .project {
            background-position: center;
            background-size: cover;
            min-height: 300px;
            transition: transform 0.3s ease, opacity 0.3s ease;
            transform-origin: center;
            will-change: transform;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
            border-radius: 8px;
            border: 2px solid black;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            overflow: hidden;
            text-align: center;
    
            &.filtered {
                opacity: .3;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 1);
                filter: blur(2px);
            }

            &:not(.filtered) {
                cursor: pointer;
                &:hover {
                    transform: scale(1.01);

                    &.animate-vertical {
                        animation: gentle-background-sway-vertical 20s ease-in-out infinite;
                    }

                    &.animate-horizontal {
                        animation: gentle-background-sway-horizontal 20s ease-in-out infinite;
                    }
                }
            }

            .project-title-wrapper {
                z-index: 2;
                width: 100%;
                padding-top: 8px;
                padding-bottom: 16px;
                background: linear-gradient(180deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, 0) 100%);

                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 1.3rem;
                    color: $text-color;
                    font-weight: bold;
                }
            }

            .project-description-wrapper {
                z-index: 2;
                width: 100%;
                padding: 16px;
                background: linear-gradient(0deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, 0) 100%);

                p {
                    margin: 0;
                    padding: 0;
                    color: $text-color;
                }

                .project-tags {
                    font-size: .95rem;
                    margin-top: 4px;
                }
            }
        }

        .project::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(circle, transparent 40%, rgba(0, 0, 0, .3) 70%, rgba(0, 0, 0, 0.8));
            pointer-events: none;
          }
    }
}

.col-span-1 {
    grid-column: span 1;
}

.col-span-2 {
    grid-column: span 2;
}

.col-span-3 {
    grid-column: span 3;
}

.row-span-1 {
    grid-row: span 1;
}

.row-span-2 {
    grid-row: span 2;
}

.row-span-3 {
    grid-row: span 3;
}

@media screen and (max-width: $tablet-break-point) {
    .projects-page {
        .project-wrapper {
            grid-template-columns: 1fr 1fr;

            .project.filtered {
                display: none;
            }
        }
    }
    
    .col-span-3 {
        grid-column: span 2;
    }
}

@media screen and (max-width: $mobile-break-point) {
    .projects-page {
        .project-wrapper {
            grid-template-columns: 1fr;
        }
        
        .project.filtered {
            display: none;
        }
    }

    .col-span-3 {
        grid-column: span 1;
    }

    .col-span-2 {
        grid-column: span 1;
    }
}