@import "../../styles/styles.scss";

.footer {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    .footer-wrapper {
        width: $default-page-width;
        max-width: $default-max-width;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        
        p {
            color: white;
            padding: 0;
            margin: 0;
        }

        .footer-links {
            display: flex;
            gap: 1rem;
            align-items: center;
            background-color: white;
            color: black;
            margin-top: 25px;
            padding: 8px 20px;
            border-radius: 25px;
            text-decoration: none;
            width: fit-content;

            * {
                color: black;
            }

            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
}