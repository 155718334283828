@import "../../../styles/styles.scss";

.project-modal-root {
    .ant-modal-mask {
        @keyframes blur {
            from {
                background-color: transparent;
                backdrop-filter:blur(0px);
            }
            to {
                background-color: $modal-mask-color;
                backdrop-filter:blur(3px);
            }
        }

        @keyframes unblur {
            from {
                background-color: $modal-mask-color;
                backdrop-filter:blur(3px);
            }
            to {
                background-color: transparent;
                backdrop-filter:blur(0px);
            }
        }

        &.opened {
            animation: blur 0.3s ease forwards;
        }

        &.closed {
            animation: unblur 0.3s ease forwards;
        }
    }

    .ant-modal-wrap{

        .ant-modal {
            max-width: 800px;
            width: 80% !important;
        }

        .ant-modal-content {
            background-color: transparent;
            color: $text-color;
            margin: 0;
            padding: 0;

            svg {
                color: $text-color;
            }

            .project-modal-content {
                box-shadow: 0 0 10px 10px $modal-mask-color;
                background-position: center;
                background-size:cover;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: center;
                padding: 32px;
                border-radius: 8px;
                border: 2px solid black;

                * {
                    z-index: 1;
                }

                .project-modal-text {
                    padding: 16px;
                    
                    .project-modal-title-wrapper {
                        .project-modal-title {
                            text-align: center;
                            margin: 0;
                            font-size: 24px;
                        }
    
                        .project-modal-tags {
                            padding: 0;
                            margin: 0;
                            text-align: center;
                        }
                    }
    
                    .project-modal-description {
                        margin-top: 48px;
                        text-align: justify;

                        p {
                            padding: 0;
                            margin: 0;
                        }
                    }
    
                    .project-modal-link {
                        margin: 0;
                        margin-top: 48px;
                        text-align: center;
                    }

                    .dim-overlay {
                        background-color: rgba(0, 0, 0, .7);
                        box-shadow: 0 0 20px 10px rgba(0, 0, 0, .8);
                        border-radius: 8px;
                        padding: 8px;
                        backdrop-filter: blur(5px);
                    }
                }
            }
        }

        .ant-modal-content::after {
            border-radius: 8px;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-color: $modal-mask-color;
            backdrop-filter: blur(2px);
            mask-image: radial-gradient(circle, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 1) 80%);
            -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 1) 80%);
            border: 2px solid black;
        }
    }
}