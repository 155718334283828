@import "../../styles/styles.scss";

.header {
    width: 100%;
    padding-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .header-wrapper {
        width: $default-page-width;
        max-width: $default-max-width;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .return-home {
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-left: 16px;
            height: 100%;

            .logo {
                height: 50px;
                border-radius: 16px;
            }

            .name {
                font-size: 30px;
                padding: 0;
                margin: 0;
                padding-left: 10px;
                text-decoration: none;
                color: white;
                text-decoration: none;
            }
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 16px;

            .nav-list {
                display: flex;
                justify-content: space-between;
                font-size: 20px;
                padding: 0;

                li {
                    list-style-type: none;
                    padding: 0 10px;
                    margin: 0;
                    cursor: pointer;

                    a {
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// Make header column when screen is small
@media screen and (max-width: $tablet-break-point) {
    .header {
        .header-wrapper {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .return-home {
                justify-content: center;
                margin: 0;
            }

            .nav {
                justify-content: center;
                margin: 0;

                .nav-list {
                    justify-content: center;
                    padding: 0;
                    margin-top: 0;

                    li {
                        padding: 0 5px;
                    }
                }
            }
        }
    }
}